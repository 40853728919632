import React from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { Tooltip } from '@mui/material';

// Inspired by the former Facebook spinners.
const Progress = styled(CircularProgress)({
    strokeLinecap: 'round',
});

/* percentage should be a number between 0 and 100 */

const CircularProgressBar = ({
  percentage,
  size = 90,    // in px
  subtext,
  dataTip,
  style
}) => {
  const percentageMapped = Math.floor(percentage);
  const derivedFontSize = size > 70 ? 16 : 12;
  return (
    <Tooltip title={dataTip}>
      <Wrapper size={size}>
        <CircularProgress
          size={size} 
          variant='determinate' 
          value={100} 
          style={{ color: 'rgba(0, 0, 0, 0.1)' }}
          className='circ-prog'
          thickness={2}
        />
        <Progress
          size={size} 
          variant='determinate' 
          value={percentageMapped} 
          className='circ-prog'
          thickness={2}
        />
        <ContentInner>
          <Typography variant='body1' style={{ fontWeight: 'bolder', fontSize: `${derivedFontSize}px` }}>{percentageMapped}%</Typography>
          {
            subtext &&
            <Typography variant='body2' className='subtext'>{subtext}</Typography>
          }
        </ContentInner>
      </Wrapper>
    </Tooltip>
  )
}

const Wrapper = styled.div`
  position: relative;
  width: ${props => props.size}px;
  height: ${props => props.size}px;

  
  & > .circ-prog {
    position: absolute;
    height: 80px;
    width: 80px;
    top: -6;
    left: -6;
    bottom: 6;
    right: 6;
  }
`

const ContentInner = styled.div`
  z-index: 1;
  padding: 0 10px;
  width: calc(100% - 10px * 2);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;

  p { 
    text-align: center;
    line-height: 1.1;
  }

  p.subtext {
    font-size: 12px;
  }
`

export { CircularProgressBar };
