import React, { useEffect } from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import ReactTooltip from 'react-tooltip';

/* percentage should be a number between 0 and 100 */

class ProgressBar extends React.Component {
  render() {
    const props = this.props;
    const percentage = props.percentage || 0;
    const { height, backgroundColor, color, borderColor } = props;
    const stylesWrapper = {};
    const stylesInner = {width: `${Math.floor(percentage)}%`};
    if(height) {
      stylesWrapper.height = `${height}px`;
      stylesWrapper.borderRadius = `${height/2}px`;
      stylesInner.borderRadius = `${height/2}px`;
    }
    if(backgroundColor) {
      stylesWrapper.backgroundColor = backgroundColor;
    }
    if(borderColor) {
      stylesWrapper.border = `solid 1px ${borderColor}`
    }
    if(color) {
      stylesInner.backgroundColor = color;
    }
    return (
      <Wrapper style={stylesWrapper}>
        <Inner style={stylesInner}/>
      </Wrapper>
    )
  }
}

const CandidateProgressBar = ({ candidate, ...props }) => {
  useEffect(() => {
    ReactTooltip.hide()
    ReactTooltip.rebuild()
  }, [candidate])
  return <div style={{ width: '100%', padding: '8px 0', display: 'flex', flexDirection: 'column' }} data-tip={
    candidate?.progress === 1.0
      ? 'All information is complete'
      : `Still need information for ${(candidate?.incompleteFields || []).reduce((acc, field, index, arr) => acc + (index % 3 === 0 ? ',<br />' : ', ') + field, '')}`
  }>
    <ProgressBar {...props} />
    <Typography variant={'body2'} style={{ marginTop: '4px'}}>{Math.round(candidate?.progress * 100) }% complete</Typography>
  </div>
}

const Wrapper = styled.div`
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: #F8F8F8;
  overflow: hidden;
`

const Inner = styled.div`
  left: 0;
  height: 100%;
  background-color: ${props => props.theme.palette.primary.main};
  transition: width 1s;
`

export { ProgressBar, CandidateProgressBar };
