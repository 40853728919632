import React, { useEffect, useCallback } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import useKeypress from 'react-use-keypress';

export const ModalInner = ({
  onClose,
  children,
  style = {},
  className = '',
  styleCloseButton = {},
  title = '',
  mode = 'auto'       // 'mobile' or 'desktop'
}: {
  onClose?: () => void,
  children: React.ReactNode,
  style?: React.CSSProperties,
  className?: string,
  styleCloseButton?: React.CSSProperties,
  title?: string | null,
  mode?: 'auto' | 'mobile' | 'desktop'
}) => {
  useKeypress('Escape', () => {
    if(onClose) onClose()
  });

  return (
    <ModalWrapper style={style} mode={mode} className={className}>
      {
        onClose &&
        (
          <IconButton
            style={{ position: 'absolute', top: '20px', right: '20px', ...styleCloseButton }}
            size="small"
            onClick={onClose}
            id='modal-close-button'
          >
            <CloseIcon style={{ width: '22px', height: '22px' }}/>
          </IconButton>
        )
      }

      {
        title &&
        <Typography variant='h2' style={{ margin: '0 0 28px' }} color='textPrimary'>
          {title}
        </Typography>
      }
      {children}
    </ModalWrapper>
  )
}

const ModalWrapper = styled.div<{ mode: 'auto' | 'mobile' | 'desktop' }>`

  background-color: #FFFFFF;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
  ${
    props => {
      if(props.mode === 'desktop') {
        return desktopStyles;
      } else if(props.mode === 'mobile') {
        return mobileStyles;
      } else {
        return `
          @media(min-width: 480px) {
            ${desktopStyles}
          }

          @media(max-width: 481px) {
            ${mobileStyles}
          }
        `
      }
    }
  }

`

const mobileStyles = `
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 24px * 2);

  padding: 24px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  max-height: calc(80vh - 24px * 2);

  #modal-close-button {
    display: none;
  }
`

const desktopStyles = `
  margin: 24px;
  padding: 36px;
  border-radius: 8px;
  max-height: calc(90vh - 36px * 2);
  max-width: 600px;
  width: calc(100% - 36px * 2 - 24px * 2);

  position: absolute;
  top: calc(50% - 24px);
  left: calc(50% - 24px);
  transform: translate(-50%, -50%);
`
