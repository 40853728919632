import React, { ReactNode } from 'react';
import {Link} from 'react-router-dom';
import { ReactComponent as DefaultIcon } from '../../images/defaultNav.svg';
import styled from 'styled-components';
import { onClickOrLink } from '../../app/util';

const MenuLink = styled(Link)`
	height: 50px;
	display: flex;
	align-items: center;
`;

const NavLink = styled.span`
	font-size: 16pt;
	color: #FFFFFF;
`;

const Icon = styled.span`
	svg {
		display: inline-flex;
		transform: translateY(2px);
		padding: 0 32px 0 20px;
		width: 18px;
		height: 18px;
	}
`;

interface MenuItemProps {
	children?: ReactNode;
	icon: ReactNode;
	matchRoutes?: string[];
	modifyChildrenOnClick?: (onClick: { to?: string }, routeParams: { key?: string }) => {},
	onClick: { to: string } | { href: string } |  Function;
	renderChildrenIf?: (route: string) => boolean,
	text: string;
}

const MenuItem = ({
	onClick,
	icon,
	text,
}: MenuItemProps) => {
	const linkProps = onClickOrLink(onClick);
	return (
		<MenuLink
			to={linkProps?.to}
			onClick={linkProps?.onClick}
			key={text}>
			<Icon>
				{ (icon) ? ( icon ): <DefaultIcon /> }
			</Icon>
			<NavLink>{ text }</NavLink>
		</MenuLink>
	)
}

export default MenuItem;
