import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Typography } from '@mui/material';
import styled from 'styled-components';

const Root = styled(Select)({
      backgroundColor: 'transparent',
      '& .MuiSelect-select': {
        backgroundColor: 'transparent',
        padding: '4px 30px 2px 8px',
        fontSize: '12px'
      },
});

const Input = styled(OutlinedInput)({
      padding: '4px 30px 2px 8px',
      borderColor: '#AAAAAA',
      borderRadius: '18px'
});

const TypeDropdown = ({
    value,
    options,
    onChange,
    placeholder
  }) => {
    const handleIssueChange = (event) => {
      event.stopPropagation();
      if(event.target.value === 'placeholder') return;
      onChange(event.target.value);
    };

    return (
      <Root
        value={value || 'placeholder'}
        size='small'
        onChange={handleIssueChange}
        variant='outlined'
        input={<Input />}>
        {
          placeholder &&
          <MenuItem value={'placeholder'}>
            <Typography variant='body1' style={{ fontSize: '14px' }}>{placeholder}</Typography>
          </MenuItem>
        }
        {options.map(({ type, title }) => (
          <MenuItem style={{whiteSpace: 'normal', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }} key={type} value={type}>
            <Typography variant='body1' style={{ fontSize: '14px' }}>{title}</Typography>
          </MenuItem>
        ))}
      </Root>
    );
  }

export { TypeDropdown };