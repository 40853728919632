import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import React, { CSSProperties } from 'react';
import { Badge, TableCell } from '@mui/material';
import { PriorityHigh, SvgIconComponent } from '@mui/icons-material';

interface DistrictIssuesProps {
  as?: string;
  issues: string[];
  issueTypes: {
    Icon: SvgIconComponent;
    key: string;
    tooltips: string[];
    to?: string;
  }[];
  style?: any;
}


const DistrictIssues = (
  {
    style,
    issues,
    issueTypes,
    as
  }: DistrictIssuesProps,
) => {
  if(as === 'td') {
    return <DistrictIssuesInner issues={issues} issueTypes={issueTypes} as='td'/>;
  }
  return (
    <div
      style={{
        display: 'flex',
        gap: 16,
        ...style,
      }}
    >
      <DistrictIssuesInner issues={issues} issueTypes={issueTypes} />
    </div>
  );
};

const DistrictIssuesInner = (
  {
    issues,
    issueTypes,
    as,
    style
  } : DistrictIssuesProps
) => {
  return (
    <React.Fragment>
      {
        issueTypes.map(({ Icon, key, tooltips, to }) => {
          const issuePresent = +issues?.includes(key);
          const buttonContent = (
            <Badge
              invisible={!issuePresent}
              badgeContent={<PriorityHigh color="error" />}
            >
              <IconButton
                disabled={!issuePresent}
                size="small">
                <Icon />
              </IconButton>
            </Badge>
          );
          const content = (
            <Tooltip
              key={key}
              title={tooltips[issuePresent]}
              style={{ opacity: issuePresent ? 1 : 0.5 }}
            >
              {
                to && issuePresent
                  ? <Link to={to}>{buttonContent}</Link>
                  : <div>{buttonContent}</div>
              }
            </Tooltip>
          )
          return (
            as === 'td'
              ? <TableCell key={key} sx={style}>{content}</TableCell>
              : content
          );
        })
      }
    </React.Fragment>
  );
}

export { DistrictIssues };