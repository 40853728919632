import React, { MouseEvent, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { loadPaginatedFrontend, useFeathers } from '../../app/util';
import { DataDetailToolbar, Tier3AddDistrictModal } from '../../components';
import { sortDistricts } from '../../app/util/sort-districts';
import { Add, Check, DeleteOutline, Edit, Search, Warning } from '@mui/icons-material';
import { Application } from '@feathersjs/feathers';
import { ServiceTypes } from '../../app/feathers/ServiceTypes';
import { District } from '../../app/feathers/districts/District';
import { stateCodeForName } from '@branchpolitics/microservice.clean.states';
import { StateConfiguration } from '../../app/feathers/state-configurations/StateConfiguration';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import { humanizeDistrictType } from '../../app/util/humanize-district-type';

const Tier2DistrictDetail = () => {
  const history = useHistory();
  const {
    matchName,
    tier2Type,
    tier2MatchName,
  } = useParams<Record<'matchName' | 'tier2Type' | 'tier2MatchName', string>>();
  const feathers = useFeathers<Application<ServiceTypes>>();
  const [stateConfiguration, setStateConfiguration] = useState<StateConfiguration>();
  const [district, setDistrict] = useState<District | null>(null);
  const [addTier3ModalOpenToType, setAddTier3ModalOpenToType] = useState<string | null>(null);
  const tier3ModalOpen = !!addTier3ModalOpenToType;
  const [typeTitle, setTypeTitle] = useState('');

  const tier3Types = useMemo(() => {
    if (!district || !stateConfiguration) {
      return [];
    }
    // first, understand which district type we are in
    const districtType = district?.type;
    const tier3Types = stateConfiguration.typeStructure[districtType];
    if (!tier3Types) {
      return [];
    }
    return tier3Types;
  }, [district, stateConfiguration]);


  const [subdistricts, setSubdistricts] = useState<District[]>([]);

  async function loadDistricts() {
    const districtService = feathers.getService('districts');
    const { data: districtData } = await districtService
      .find({ query: { matchName: tier2MatchName, $includeChildrenStructure: true } });
    const district = districtData[0];

    setDistrict(district);
    if (district?._id) {
      const stateCode = stateCodeForName(district.parent);
      const [
        subdistricts,
        stateConfiguration
      ] = await Promise.all([
        loadPaginatedFrontend<District>(
          'districts',
          { parentId: district._id },
          feathers,
          500,
        ),
        feathers.getService('state-configurations').get(stateCode.toLowerCase()),
      ]);
      sortDistricts(subdistricts);
      setSubdistricts(subdistricts);
      setStateConfiguration(stateConfiguration);
      setTypeTitle(`${humanizeDistrictType(tier2Type, stateConfiguration?.typeConfigurations)} Districts`);
    }
  }

  useEffect(() => {
    if (!feathers) {
      return;
    }
    loadDistricts();
  }, [feathers]);

  return (
    <Wrapper>
      <DataDetailToolbar
        navTree={[
          { text: 'Districts', to: '/states' },
          { text: district?.parent || '--', to: `/states/${matchName}` },
          { text: typeTitle, to: `/states/${matchName}/${tier2Type}` },
          {
            text: (district?.name || district?.number || district?.letter) || '--',
            to: `/states/${matchName}/${tier2Type}/${tier2MatchName}`
          },
        ]}
        onBack={() => history.push(`/states/${matchName}/${tier2Type}`)}
      />
      <Typography variant='h2' style={{ marginTop: '24px' }}>{district?.longName}</Typography>
      <Typography variant='body1'>Short name: {district?.shortName}</Typography>
      {
        !!district &&
        <div style={{ display: 'flex', gap: 12, flexDirection: 'column', marginTop: '48px' }}>
          <Typography variant='body1'>
            <b>Population:</b> {district.population.toLocaleString()}
          </Typography>
        </div>
      }
      {
        !!district && !!stateConfiguration && tier3Types.length > 0 &&
        <>
          <Typography style={{ marginTop: '48px' }} variant='h2'>Subdistricts</Typography>
          {
            tier3Types.map((tier3Type, idx) => {
              const humanizedTier3Type = humanizeDistrictType(tier3Type, stateConfiguration.typeConfigurations);
              const childrenForType = subdistricts.filter(subdistrict => subdistrict.type === tier3Type);
              const typeIsOptional = stateConfiguration.typeConfigurations.find(typeConfig => typeConfig.type === tier3Type)?.optionalForStructure;
              const childrenExpected = (district.childrenStructure || {})[tier3Type];
              let childrenFinished;
              if (typeIsOptional) {
                childrenFinished = true;
              } else if (childrenExpected === -1 && !typeIsOptional) {
                childrenFinished = false;
              } else {
                childrenFinished = childrenExpected === childrenForType.length;
              }

              const quickQueryTerm = `${district.shortName} ${humanizedTier3Type} ${district.parent}`.replace(/ /g, '+');
              const quickQuery = `https://www.google.com/search?q=${quickQueryTerm}`;

              const notesForType = district.notes?.filter(note => note.startsWith(`${tier3Type}:`));
              const note = (notesForType && notesForType.length > 0) ? notesForType[0].split(':')[1] : null;
              const sourcesForType = district.sources?.filter(source => source.type === tier3Type).map(source => source.url);

              return (
                <ChildSection style={idx === 0 ? { marginTop: '12px' } : {}}>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: 12,
                    alignItems: 'center',
                    width: '100%',
                    borderBottom: 'solid 1px #ccc'
                  }}>
                    <div style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
                      <Typography variant='h4'>{humanizedTier3Type}</Typography>
                      {childrenFinished ? <Check style={{ fontSize: '20px' }}/> :
                        <Warning style={{ fontSize: '20px' }}/>}
                      <Tooltip title='Google search for this district type'>
                        <IconButton size='small' href={quickQuery} target='_blank'>
                          <Search/>
                        </IconButton>
                      </Tooltip>
                    </div>
                    {childrenForType?.length === 0 &&
                      <IconButton onClick={() => setAddTier3ModalOpenToType(tier3Type)}>
                        <Add/>
                      </IconButton>
                    }
                    {
                      childrenForType?.length > 0 &&
                      <IconButton onClick={() => setAddTier3ModalOpenToType(tier3Type)}>
                        <Edit/>
                      </IconButton>
                    }
                  </div>
                  {
                    childrenForType.length === 0 && typeIsOptional &&
                    <Typography variant='body1'>No {humanizedTier3Type} districts found, but this type is
                      optional.</Typography>
                  }
                  {
                    childrenForType.length === 0 && !typeIsOptional && childrenExpected === 0 &&
                    <Typography variant='body1'>There are no {humanizedTier3Type} districts
                      in {district.longName}.</Typography>
                  }
                  {
                    childrenForType.length === 0 && !typeIsOptional && childrenExpected !== 0 &&
                    <Typography variant='body1'>No {humanizedTier3Type} districts created yet.</Typography>
                  }
                  {childrenForType.length > 0 &&
                    <>
                      <TableContainer style={{ border: 'solid 1px #eee', maxHeight: '500px' }} component={Paper}>
                        <Table size='small' aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell style={{ width: '100%' }}>District</TableCell>
                              <TableCell>Population</TableCell>
                              <TableCell style={{ whiteSpace: 'nowrap'}}>At-large?</TableCell>
                              <TableCell>Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              childrenForType
                                .map(subdistrict => {
                                  return (
                                    <TableRow>
                                      <TableCell>{subdistrict.longName}</TableCell>
                                      <TableCell
                                        style={{ textAlign: 'center' }}
                                      >
                                        {subdistrict.population.toLocaleString()}
                                      </TableCell>
                                      <TableCell
                                        style={{ textAlign: 'center' }}
                                      >
                                        {subdistrict.votingType === 'voting-district' ? 'No' : 'Yes'}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  }
                  {
                    !!note && 
                    <Typography variant='body1' style={{ marginTop: '12px' }}>
                      <b>Note:</b> {note}
                    </Typography>
                  }
                  {
                    !!sourcesForType && sourcesForType.length > 0 &&
                    <Typography variant='body2' style={{ marginTop: '6px' }}>
                      <b>Sources:</b>
                      {sourcesForType.map((source, idx) => {
                          return (
                            <>
                              <a href={source} style={{ marginLeft: '6px' }} target='_blank'>{source}</a>
                              {idx !== sourcesForType.length - 1 && <>, </>}
                            </>
                          );
                        })
                      }
                    </Typography>
                  }
                </ChildSection>
              )
            })
          }
        </>
      }
      <Tier3AddDistrictModal
        open={tier3ModalOpen}
        existingSubdistricts={addTier3ModalOpenToType ? (subdistricts || []).filter(d => d.type === addTier3ModalOpenToType) : []}
        onClose={() => setAddTier3ModalOpenToType(null)}
        stateConfiguration={stateConfiguration}
        onFinish={(parentDistrict, subdistricts) => {
          setSubdistricts(sd => {
            // filter out all districts of type
            const newSubdistricts = sd.filter(d => d.type !== addTier3ModalOpenToType);
            // add all new districts
            return newSubdistricts.concat(subdistricts);
          })

          setDistrict(parentDistrict)

          setAddTier3ModalOpenToType(null);
        }}
        parentDistrict={district}
        tier3Type={addTier3ModalOpenToType || ''}
      />
    </Wrapper>
  );
};


const Wrapper = styled.div`
  width: calc(100% - 2 * 24px);
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: stretch;
`;

const ChildSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 36px;
  max-width: 700px;
`;

export default Tier2DistrictDetail;
