import { TypeConfiguration } from '../feathers/state-configurations/StateConfiguration';

export function humanizeDistrictType(districtType: string, typeConfigurations: TypeConfiguration[] | null = null): string {
	const typeConfig = (typeConfigurations || []).find(typeConfig => typeConfig.type === districtType);
	if(typeConfig?.prettyName) {
		return typeConfig.prettyName;
	} 

	return districtType
      .replaceAll('us-', 'US-')
      .replaceAll('-', ' ')
      .replace(/\b\w/g, char => char.toUpperCase());
}