import React, { useMemo } from 'react';
import styled from 'styled-components';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Cancel';
import InputLabel from '@mui/material/InputLabel';

const Root = styled(Select)({
  backgroundColor: 'transparent',
  '& .MuiSelect-select': {
    backgroundColor: 'transparent',
    padding: '8px 24px 4px 8px',
    fontSize: '12px',
  },
});

const Input = styled(OutlinedInput)({
    borderColor: '#AAAAAA',
    borderRadius: '18px',
});

// Has has a dropdown menu, when something is selected it appears in the chips above, and you can deselect it by clicking the x on the chip
const ChipMultiSelectMenu = ({
                               selectedValues = [],
                               options = [],
                               selectOptionFunction = () => {
                               },
                               deselectOptionFunction = () => {
                               },
                               displayProp = '', // property on the values array that is displayed in the menu
                               labelText = '',
                               selectStyle = {},
                               style = {},
                               verticalChips = false, // if true will display the chips in a column isnstead of row
                             }) => {
  const handleIssueChange = (event) => {
    event.stopPropagation();
    selectOptionFunction(event.target.value);
  };

  const filteredOptions = useMemo(() => {
    return options.filter(o => {
      return !selectedValues.map(v => v[displayProp])?.includes(o[displayProp]);
    });
  }, [selectedValues, options]);

  return (
    <Wrapper style={{ ...style }}>
      <ChipSectionStyles verticalChips={verticalChips}>
        {
          selectedValues?.map((option, i) => (
            <Chip
              key={option[displayProp]}
              onDelete={() => {
                deselectOptionFunction(option);
              }}
              label={option[displayProp]}
              style={{ margin: '4px' }}
            >
            </Chip>
          ))
        }
      </ChipSectionStyles>
      <Root
        style={{ width: '400px', ...selectStyle }}
        value={selectedValues}
        size="small"
        onChange={handleIssueChange}
        variant="outlined"
        input={
          <Input
            helperText="help"
          />
        }
      >
        {
          filteredOptions.map((option, i) => (
            <MenuItem style={{ whiteSpace: 'normal', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}
                      key={option[displayProp]} value={options.find(o => o[displayProp] === option[displayProp])}>
              <Typography variant="body1" style={{ fontSize: '14px' }}>{option[displayProp]}</Typography>
            </MenuItem>
          ))}
      </Root>
      <InputLabel id="label" style={{ margin: '-34px 0px 0px 12px' }}>{labelText}</InputLabel>
    </Wrapper>
  );
};


const Chip = ({
                label,
                onDelete,
              }) => {
  return (
    <ChipWrapperCustom>
      <Typography variant="body1">{label}</Typography>
      {onDelete && <IconButton size="small" onClick={onDelete}><CloseIcon fontSize="small" /></IconButton>}
    </ChipWrapperCustom>
  );
};


const ChipSectionStyles = styled.div`
  margin-bottom: 6px;
  gap: 12px;
  display: flex;
  flex-direction: ${props => props.verticalChips ? 'column' : 'row'};
  align-items: flex-start
`;

const ChipWrapperCustom = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 4px;
  background-color: #D6E2FB;
  padding: 4px 8px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export { ChipMultiSelectMenu };
