import React, { useState, useCallback, ReactNode } from 'react';
import styled from 'styled-components'
import MenuItem from './MenuItem'
import {useLocation, useHistory} from 'react-router-dom'
import { useFeathers } from '../../app/util'
import { userLogout } from '../../app/actions'
import { useDispatch } from 'react-redux'
import { ReactComponent as LogoutIcon } from './logoutNav.svg'
import BranchIcon from '../../images/BranchLogo_White_-02.png'
import BranchIconSmall from '../../images/BranchLogo_Small_White_-02.png'
import IconButton from '@mui/material/IconButton'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const navigationWidth = 256
const navigationCollapsedWidth = 64

const Wrapper = styled.div<{ collapsed: boolean }>`
  top: 0;
  left: 0;
  width: ${({ collapsed }) =>
          collapsed ? navigationCollapsedWidth : navigationWidth}px;
  transition: width 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

  height: 100%;
  overflow-x: visible;
  overflow-y: auto;
  z-index: 1;
`

const WrapperInner = styled.div<{ collapsed: boolean }>`
  height: 100%;
  background: #22034f;
  width: 100%;

  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-x: hidden;

  h3 {
    font-size: 14px;
    color: white;
  }
  a {
    text-decoration: none;
  }
  img {
    height: ${({ collapsed }) => (collapsed ? '20' : '60')}px;
    margin: ${({ collapsed }) => (collapsed ? '44' : '24')}px 0
    ${({ collapsed }) => (collapsed ? '32' : '12')}px;
  }
  div.navTitleLine {
    width: 100%;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.16);
  }
  div.linkListContainer {
    width: 100%;
    padding: 0;
    margin: 12px 0 12px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
`

interface NavLinkProps {
  active?: boolean;
  bottom?: boolean;
  collapsed?: boolean;
  level?: number;
}

const NavLink = styled.div<NavLinkProps>`
  left: 0;
  padding-top: 5px;
  color: white;
  height: 50px;
  width: ${navigationWidth}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &:hover {
    background: rgba(255, 255, 255, 0.12);
  }
  ${(props) =>
          props.active
                  ? `background: rgba(255, 255, 255, 0.12);
      border-left: 4px solid  #FFFFFF;`
                  : ``}
  ${(props) => (props.bottom ? `margin-top: 150px;` : ``)}

  transition: padding-left 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

  ${props =>
    props.collapsed
      ? ``
      : props.level === 2 ? `padding-left: 24px;` : ``
  }

`

interface CollapseButtonProps {
  collapsed: boolean;
}


const CollapseButton = styled(IconButton)<CollapseButtonProps>`
  position: absolute !important;
  bottom: 0;
  left: calc(${({collapsed}) => (collapsed ? navigationCollapsedWidth : navigationWidth) }px - 12px );
  border-radius: 0% 50% 50% 0% !important;
  border: solid 1px #22034f !important;

  &.MuiIconButton-root {
    z-index: -1;
    background-color: #FFFFFF;
    transition: left 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  transition: left 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  .MuiTouchRipple-root {
    transition: background 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  :hover .MuiTouchRipple-root {
    background: rgba(34, 3, 79, 0.12);
  }

  .MuiSvgIcon-root {
    transform: rotate(${({ collapsed }) => (collapsed ? '0deg' : '-180deg')});
    transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  .muitouchripple-child {
    background: rgba(34, 3, 79, 0.24) !important;
    border-radius: 0% 50% 50% 0% !important;
  }
`

interface NavigationPaneProps {
  children: ReactNode;
  style?: React.CSSProperties;
  toHome: { to: string };
}

const NavigationPane = (props: NavigationPaneProps) => {
  const children = props?.children;
  const style = props?.style;
  const location = useLocation()
  const feathers = useFeathers()
  const history = useHistory()
  const dispatch = useDispatch()
  const [ collapsed, setCollapsed ] = useState(true);
  const [ uncollapsedByButton, setUncollapsedByButton ] = useState(false);

  const onMouseEnter = useCallback(() => {
    if (collapsed && !uncollapsedByButton) {
      setCollapsed(false)
    }
  }, [collapsed, uncollapsedByButton ])

  const onMouseLeave = useCallback(() => {
    if (!collapsed && !uncollapsedByButton) {
      setCollapsed(true)
    }
  }, [collapsed, uncollapsedByButton])
  const onLogout = async () => {
    dispatch(userLogout())
    history.push('/')
  }

  const hideNavPaneOnRoutes = ['/tasks/identify-races']
  const hideNavPane = location.pathname && hideNavPaneOnRoutes.some(route => location.pathname.includes(route))

  if(hideNavPane) return null;

  return (
    <Wrapper
      style={style}
      collapsed={collapsed}
    >
      <WrapperInner
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        collapsed={collapsed}
      >
        {collapsed ? (
          <img
            src={BranchIconSmall}
            alt="Branch"
            style={{ cursor: 'pointer'}}
            onClick={() => history.push('/home')}
          />
        ) : (
          <img
            src={BranchIcon}
            alt="Branch"
            style={{ cursor: 'pointer'}}
            onClick={() => history.push('/home')}
          />
        )}
        <div className="navTitleLine" />
        <div className="linkListContainer">
          {((Array.isArray(children) ? children : [ children ])?.filter(Boolean).map((child, index) => {
            const children = (child.props.children ? (Array.isArray(child.props.children) ? child.props.children : [child.props.children] ) : []).filter(Boolean);
            const routeActive = child.props.matchRoutes?.some((r: string) => location.pathname.match(new RegExp(`^${r}$`)))
            const childrenRouteActive = children.length > 0 && routeActive &&
              child.props.renderChildrenIf && child.props.renderChildrenIf(location.pathname);

            const routeParams = childrenRouteActive;

            return (
              <React.Fragment key={`nav-link-${index}`}>
                <NavLink
                  level={1}
                  collapsed={collapsed}
                  active={routeActive}
                >
                  {child}
                </NavLink>
                {
                  childrenRouteActive &&
                  children.map((innerChild: ReturnType<typeof MenuItem>, innerIndex: number) => {
                    const childOnClick = innerChild.props.onClick;
                    const onClickNew = child.props.modifyChildrenOnClick
                      ? child.props.modifyChildrenOnClick(childOnClick, routeParams)
                      : childOnClick;

                    return (
                      <NavLink
                        level={2}
                        collapsed={collapsed}
                        active={innerChild.props.matchRoutes?.some((r: string) => location.pathname.match(new RegExp(`^${r}$`)))}
                        key={`${index}-${innerIndex}`}
                      >
                        {React.cloneElement(innerChild, { onClick: onClickNew })}
                      </NavLink>
                    )
                  })
                }
              </React.Fragment>
            );
          }))}
        </div>
        <div className="linkListContainer" style={{ margin: '36px 0 12px'}}>
          <NavLink>
            <MenuItem
              onClick={onLogout}
              text="Sign out"
              icon={<LogoutIcon />}
            />
          </NavLink>
        </div>
      </WrapperInner>
      <CollapseButton
        onClick={() => {
          if(collapsed) setUncollapsedByButton(true)
          else setUncollapsedByButton(false)
          setCollapsed(!collapsed)
        }}
        size='medium'
        color="primary"
        aria-label="collapse"
        collapsed={collapsed}
      >
        <ArrowForwardIosIcon />
      </CollapseButton>
    </Wrapper>
  )
}

export default NavigationPane
