import React from 'react';
import styled from 'styled-components';
import { Skeleton } from '@mui/material';
import { Link, Typography } from '@mui/material';
import moment from 'moment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CancelIcon from '@mui/icons-material/Cancel';
import { ChevronLeftRounded } from '@mui/icons-material';
import { ChevronRightButton } from '../lower-order';

const ErrorPreviewTable = ({
  errors,
  loading,
	style,
	className,
}) => {
	console.log(errors)
  // top part shows as many rows in the array with loading bars until is loaded
  return (
		<Table numRows={loading ? 3 : errors.length} style={style} className={className} numColumns={3}>
			{
				loading && [1, 2, 3].map((index) => (
					<React.Fragment key={index}>
						<RowSpacer style={{ gridRow: `${index + 1} / ${index + 2}` }}/>
						<Cell style={{ gridRow: `${index + 1} / ${index + 2}`, gridColumn: `1 / 2` }}>
							<Skeleton variant='text' width='180px' height='20px'/>
						</Cell>
						<Cell style={{ gridRow: `${index + 1} / ${index + 2}`, gridColumn: `2 / 3` }}>
							<Skeleton variant='text' width='40px' height='20px'/>
						</Cell>
						<Seperator style={{ gridRow: `${index + 1} / ${index + 2}` }}/>
					</React.Fragment>
				))
			}
			{
				!loading && (errors || []).map((error, index) => {
					return (
						<RowToRender error={error} key={index} rowNumber={index + 1} numRows={errors.length}/>
					)
				})
			}
		</Table>
  )
}


const RowToRender = ({
	error,
	rowNumber,
	numRows,

}) => {
	const typeWritten = error.type.slice(0, 1).toUpperCase() + error.type.slice(1) + ' error';
	const electionWritten = error.election ? error.election.split('-').map((word) => word.slice(0, 1).toUpperCase() + word.slice(1)).join(' ') : null;

	const gridRow = `${rowNumber} / ${rowNumber + 1}`;
	return (
		<>
			<RowSpacer style={{ gridRow }}/>
			<Cell style={{ gridRow, gridColumn: `${0 + 1} / ${0 + 2}` }}>
				<div>
					<Typography variant='body1' style={{ fontWeight: 'bold' }}>
						{typeWritten}
					</Typography>
					<Typography variant='body2'>
						{electionWritten}
					</Typography>
				</div>
			</Cell>
			<Cell style={{ gridRow, gridColumn: `${1 + 1} / ${1 + 2}` }}>
				<div style={{ display: 'flex', gap: '8px' }}>
					{
            error.status === 'resolved' &&
            <CheckCircleIcon  fontSize='small'style={{ color: 'green' }} />
          }
          {
            (error.status === 'open') &&
            <AccessTimeIcon  fontSize='small'/>
          }
          {
            (error.status === 'resolved-as-ignore') &&
            <CancelIcon fontSize='small' style={{ color: '#880000' }} />
          }
          <div>
            {
              error.status === 'resolved' &&
              <>
                <Typography variant='body1'><b>Completed</b></Typography>
                <Typography variant="body2">Completed by {error.resolvedByUser?.name ?? error.resolvedByUser?.firstName ?? error.resolvedByUser?.email} on {moment(error.updatedAt).format('MMM Do, h:mma')}</Typography>
              </>
            }
            {
              error.status === 'open' &&
              <>
                <Typography variant='body1'><b>Unresolved</b></Typography>
              </>
            }
            {
              error.status === 'resolved-as-ignore' &&
              <>
                <Typography variant='body1'><b>Ignored</b></Typography>
								<Typography variant="body2">Completed by {error.resolvedByUser?.name ?? error.resolvedByUser?.firstName ?? error.resolvedByUser?.email} on {moment(error.updatedAt).format('MMM Do, h:mma')}</Typography>
              </>
            }
          </div>
				</div>
			</Cell>
			<Cell style={{ gridRow, gridColumn: `${2 + 1} / ${2 + 2}` }}>
				<Typography variant='body2'>
					{(error.note || error.description || '').slice(0, 100)}
				</Typography>
			</Cell>
      <Cell style={{ gridRow, gridColumn: `${3 + 1} / ${3 + 2}`, justifySelf: 'end'  }}>
				<Link href={`/reported-errors/${error._id}`}>
					<ChevronRightButton fontSize='small'/>
				</Link>
			</Cell>
			{
				rowNumber !== numRows && <Seperator style={{ gridRow }}/>
			}
		</>
	)
}


const Table = styled.div`
	padding: 0 24px 0;
	display: grid;
	grid-gap: 0 12px;
	grid-template-columns: repeat(${props => props.numColumns}, auto);
	grid-template-rows: repeat(${props => props.numRows}, auto);
	text-decoration: none;
	border: solid 1px #EEEEEE;
	border-radius: 8px;
	background-color: #FFFFFF;
	align-items: center;
	width: calc(100% - 24px * 2);

	p {
		text-decoration: none;
	}
`

const RowSpacer = styled.div`
	grid-column: 1 / -1;
	height: 72px;
	width: 100%;
`

const Cell = styled.div`
	align-self: center;
`

const Seperator = styled.div`
	grid-column: 1 / -1;
	height: 1px;
	align-self: end;
	background-color: #EEEEEE;
	width: calc(100% + 24px * 2);
	margin-left: -24px;
`

export { ErrorPreviewTable };
