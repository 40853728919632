import React from 'react';
import { CandidateNamePhoto } from '../CandidateNamePhoto';
import { ChevronRightButton } from '../lower-order';
import { BluePill } from '../BluePill';
import styled from 'styled-components';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { CirclePhotoStack } from '../CirclePhotoStack';
import { CoverageTag, CoverageIcon, PartyTag } from '../lower-order';
import Typography from '@mui/material/Typography';
import { ProgressBar } from '../ProgressBar';
import VoterIcon from '@mui/icons-material/Person';
import ViewIcon from '@mui/icons-material/Visibility';
import WarningIcon from '@mui/icons-material/Warning';
import { Tooltip } from '@mui/material';

const RacePreviewTile = ({
  title,
  resultSummary,
  showResultsLinked,
  expectedReaders,
  retention,
  qualifyingPassed,
  party,
  district,
  progress,
  to,
  updatedAt,
  coverageStatus,
  willCover,
  numViews,
  numVoters,
  synced,
  candidateSummary
}) => { 

  const numViewsPretty = numViews >= 1000 ? `${Math.round(numViews/100)/10}K` : numViews;
  const numVotersPretty = numVoters >= 1000 ? `${Math.round(numVoters/100)/10}K` : numVoters;
  const expectedReadersPretty = expectedReaders >= 1000 ? `${Math.round(expectedReaders/100)/10}K` : Math.round(expectedReaders ?? 0);

  const {
    numCandidates,
    numCandidatesNotQualified,
    photos
  } = (candidateSummary || {});

  return (
    <Tile to={to}>
      <TileInner>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
          <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
            <h3 style={{ fontSize: '17px', margin: '0' }}>{title}{party && ` (${party})`}</h3>
          </div>
          <Typography variant='body1' style={{ fontSize:'14px' }}>{`${district}${retention ? ` · Retention election` : ''}`}</Typography>
        </div>
        <Candidates>
          {
            numCandidates > 0 &&
            <CirclePhotoStack photoPaths={photos} />
          }
          {
            (showResultsLinked)
            ? null
            : <Typography variant='body1' style={{ fontSize: '14px', width: '100%', marginBottom: '-4px', marginLeft: '4px' }}>{numCandidates} candidate{numCandidates === 1 ? '' : 's'}</Typography>
          }
          {
            (showResultsLinked)
            ?
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: '4px' }}>
                <ProgressBar percentage={resultSummary?.percentageLinked || 0} />
                <Typography variant='body1' style={{ opacity: 1.0, fontSize: '12px', marginTop: '2px' }}>{resultSummary?.numCandidatesLinked ?? 0}/{resultSummary?.numCandidates ?? numCandidates} candidates linked</Typography>
              </div>
            :
            <>{
              numCandidatesNotQualified > 0 &&
              <Typography variant='body1' style={{ opacity: 0.6, fontSize: '12px', marginLeft: '4px' }}>{`${numCandidatesNotQualified} didn't qualify`}</Typography>
            }</>
          }
        </Candidates>
        {
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
              <CoverageIcon willCover={willCover} coverageStatus={coverageStatus} style={{ }} />
              {
                synced === false &&
                <Tooltip title='Upublished changes'>
                  <WarningIcon style={{ color: 'orange' }} />
                </Tooltip>
              }
            </div>
            <LastUpdated>Updated {`${moment(updatedAt).format(`MMM D, h:mm a`)} `}</LastUpdated>
          </div>
        }
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <VoterIcon style={{ height: '20px', width: '20px', color: '#080808' }}/>
          <Typography
            variant='body1'
            style={{ fontSize: '15px', marginLeft: '2px' }}
          >
            {expectedReadersPretty}
          </Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ViewIcon style={{ height: '20px', width: '20px', color: '#080808' }}/>
          <Typography
            variant='body1'
            style={{ fontSize: '15px', marginLeft: '6px' }}
          >
            {numViewsPretty}
          </Typography>
        </div>
        <ChevronRightButton style={{ width: '17px', height: '17px' }}/>
      </TileInner>
    </Tile>
  )
}

const Tile = styled(Link)`
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;

  border-bottom: 1px solid #EEEEEE;
  text-decoration: none;

  &:hover {
    background-color: #F6F6F6;
    cursor: pointer;
  }
`;

const TileInner = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 70px 70px 25px;
  grid-gap: 24px;
  align-items: center;
`;

const Candidates = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
`;

const LastUpdated = styled.span`
  font-size: 12px;
  opacity: 0.6;
`

export { RacePreviewTile };
