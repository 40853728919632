import React from 'react';
import styled from 'styled-components';
import {CirclePhoto} from "../CirclePhoto";
import Typography from "@mui/material/Typography";
import { ProgressBar } from "../ProgressBar";
import moment from 'moment';
import Button from "@mui/material/Button";
import {useHistory} from "react-router-dom";
import {ChevronRightButton} from "../lower-order";
import {DueDate} from "../DueDate";

const AssignmentPreviewTable = ({
  style,
  className,
  assignments,
  electionKey
}) => {
  const history = useHistory();

  const closedInfoForAssignment = (a) => {
    if(!a.closed) return 'Open';

    if(a.closedReason === 'completion') {
      const lastSubmittedDate = a.profiles.reduce((acc, val) => !acc ? moment(val.submittedAt) : (acc.isAfter(moment(val.submittedAt)) ? acc : moment(val.submittedAt)), null)

      return `Finished on ${lastSubmittedDate.format('MMM DD')}`
    } else {
      return `Closed by manager on ${moment(a.updatedAt).format('MMM DD')}`
    }
  }

  const headers = [
    '# Candidates',
    '% Submitted',
    '% Accepted',
    '',
    'Due date',
    'Status'
  ]
  return (
    <Wrapper style={style} className={className}>
      <Header>
        {
          headers.map(h => (
            <Typography variant='body1' style={{ fontWeight: 'bold', opacity: 0.7, fontSize: '14px' }} key={h}>{h}</Typography>
          ))
        }
      </Header>
      <WrapperInner>
        {
        (assignments || []).map((a, i) => (
          <React.Fragment key={a?._id}>
            <Row onClick={() => history.push(`/elections/${a?.election || electionKey}/assignments/${a._id}`)}>
              <Typography variant={'body1'}>{a?.analytics?.numCandidates} candidates</Typography>
              <div style={{ marginTop: '10px'}}>
                <ProgressBar percentage={a?.analytics?.percentageCandidatesSubmitted} backgroundColor={'#D8D8D8'}/>
                <Typography variant={'body1'} style={{ marginTop: '2px', fontSize: '14px' }}>
                  {a?.analytics?.numCandidatesSubmitted}/{a?.analytics?.numCandidates} submitted
                </Typography>
              </div>
              <div style={{ marginTop: '10px'}}>
                <ProgressBar percentage={a?.analytics?.percentageCandidatesAccepted} backgroundColor={'#D8D8D8'}/>
                <Typography variant={'body1'} style={{ marginTop: '2px', fontSize: '14px' }}>
                  {a?.analytics?.numCandidatesAccepted}/{a?.analytics?.numCandidates} accepted
                </Typography>
              </div>
              <div />
              <DueDate
                date={a.extensionDueBy || a.dueBy}
                showColor={!a?.closed}
                extensionGranted={Boolean(a.extensionDueBy)}
              />
              <Typography variant='body1'>
                {closedInfoForAssignment(a)}
              </Typography>
              <ChevronRightButton />
            </Row>
            { i !== (assignments.length - 1) &&
              <div style={{ backgroundColor: '#D8D8D8', height: '1px', with: '100%' }}/>
            }
          </React.Fragment>
        ))
      }
      </WrapperInner>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const WrapperInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #FFFFFF;
  border: solid 1px #D8D8D8;
  border-radius: 8px;
`

const gridLayout = `
  display: grid;
  padding-left: 18px;
  padding-right: 18px;
  grid-template-columns: 1fr 150px 150px 50px 200px 250px 40px;
  grid-gap: 36px;
  align-items: center;
`
const Header = styled.div`
  ${gridLayout}
  padding-bottom: 2px;
`

const Row = styled.div`
  ${gridLayout}
  cursor: pointer;
  padding-top: 12px;
  padding-bottom: 12px;
`

export { AssignmentPreviewTable };