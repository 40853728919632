import React from 'react';
import { Typography } from '@mui/material';
import { humanizeDistrictType } from '../../app/util/humanize-district-type';
import { humanizeList } from '../../app/util';

// Function to return a structured JSON object
export function getStateConfigurationText(stateConfig) {

	// first we describe what constitutes a full district match
	let districtMatch = '';
	const tier2Districts = (stateConfig.typeConfigurations || []).filter(typeConfig => typeConfig.tierNumber === 2);
	const requiredTier2Districts = tier2Districts.filter(typeConfig => typeConfig.requiredForFullMatch === 'yes');
	districtMatch += `Every voter is expected to have ` + humanizeList(requiredTier2Districts.map((typeConfig, i, a) => `a ${humanizeDistrictType(typeConfig.type, stateConfig.typeConfigurations)}`), 'and') + `.`;

	const jointMatches = (stateConfig.typeConfigurations || []).filter(typeConfig => typeConfig.requiredForFullMatch.startsWith('requiredWithout:'));
	if(jointMatches.length > 0) {
		const jointMatchSets = jointMatches.reduce((acc, typeConfig) => {
			const typesPresent = [typeConfig.type, ...typeConfig.requiredForFullMatch.split(':')[1].split(',')];
			const existingSet = acc.find(set => set.some(type => typesPresent.includes(type)));
			if(existingSet) {
				typesPresent.forEach(type => {
					if(!existingSet.includes(type)) {
						existingSet.push(type);
					}
				});
			} else {
				acc.push(typesPresent);
			}
			return acc;
		}, [])
		jointMatchSets.forEach((jointMatchSet) => {
			districtMatch += ` Every voter is expected to have either ` + humanizeList(jointMatchSet.map((type, i, a) => `a ${humanizeDistrictType(type, stateConfig.typeConfigurations)}`), 'or') + `.`;
		});
	}

	// then we describe what measures can be created
	const measureTypes = (stateConfig.typeConfigurations || []).filter(typeConfig => typeConfig.supportsMeasures);
	let measures = '';
	if(measureTypes.length > 0) {
		measures = `Measures can only be created by ${humanizeList(measureTypes.map((typeConfig, i, a) => humanizeDistrictType(typeConfig.type, stateConfig.typeConfigurations)), 'or')}.`;
	} else {
		measures = `No measures can be created.`;
	}

	return {
		districtMatch,
		measures
	};
}

// React component to display the text information
export function PreviewStateConfiguration({ stateConfig, className, style }) {
	const { districtMatch, measures } = getStateConfigurationText(stateConfig);

	return (
		<div className={className} style={style}>
			<Typography variant='body1'><b>District match logic:</b></Typography>
			<Typography variant='body1' mb={2}>{districtMatch}</Typography>
			<Typography variant='body1'><b>Measures logic:</b></Typography>
			<Typography variant='body1'>{measures}</Typography>
		</div>
	);
}