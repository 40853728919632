import React, { useState, useEffect, useCallback } from 'react';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import OutlinedInput from '@mui/material/OutlinedInput';

import { styled } from '@mui/material/styles';

const Root = styled(Select)({
  backgroundColor: 'transparent',
  '& .MuiSelect-select': {
    backgroundColor: 'transparent',
    padding: '4px 30px 2px 8px',
    fontSize: '12px'
  },
});

const Input = styled(OutlinedInput)({
    padding: '4px 30px 2px 8px',
    borderColor: 'transparent',
    borderRadius: '18px'
});

const AddSource = ({
  onAdd,
  possibleSources,         // each item here expected to have a 'displayAs' property and a 'value' property
  sourcesAlreadyUsed,      // each item here is expected to be formatted as a source ('value' property)
}) => {
  const [ begunAdding, setBegunAdding ] = useState(true);
  const existingSources = (sourcesAlreadyUsed || []).map(es => es?.url);
  const sourcesToShow = (possibleSources || []).filter(ps => !existingSources.includes(ps?.value?.url))

  const onAddInternal = ({ target }) => {
    const sourceFullAsLink = (possibleSources || []).find(ps => ps?.value?.url === target.value)?.value;
    if(sourceFullAsLink) {
      const convertedToSource = {
        ...sourceFullAsLink,
        sourceType: ['website','questionnaire'].includes(sourceFullAsLink?.mediaType)
            ? sourceFullAsLink?.mediaType
            : 'other'
      }
      onAdd(convertedToSource)
    }
    // setBegunAdding(false);
  }

  if(sourcesToShow?.length === 0) {
    return null;
  }

  // Display a simple plus button
  if(!begunAdding) {
    return (
      <IconButton onClick={() => setBegunAdding(true)} size='small'>
        <AddIcon/>
      </IconButton>
    );
  }

  // If they've started adding but haven't selected a source
  if(begunAdding) {
    return (
      <Root
        onChange={onAddInternal}
        name='sourceType'
        value={'basic'}
        input={<Input />}
      >
        <MenuItem value="basic" disabled>
          Add source
        </MenuItem>
        {
          sourcesToShow.map(({ value, displayAs}, idx) => <MenuItem value={value?.url} key={idx}>{displayAs}</MenuItem>)
        }
      </Root>
    )
  }
}

export { AddSource };
