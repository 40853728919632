import React, { CSSProperties, ReactNode, useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import Div100vh from 'react-div-100vh';
import Login from './screens/Login/Login';
import Home from './screens/Home';
import Races from './screens/Races';
import CandidatesList from './screens/CandidatesList/CandidatesList';
import CandidateDetail from './screens/CandidateDetail';
import Elections from './screens/Elections';
import RaceDetail from './screens/RaceDetail';
import { useDispatch, useSelector } from 'react-redux';
import { setElections, userLogin, userLogout } from './app/actions';
import styled, { ThemeProvider } from 'styled-components';
import { adaptV4Theme, createTheme, ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import { FeathersProvider, isPermitted, taskConfigurations } from './app/util';
import Offices from './screens/Offices';
import OfficeDetail from './screens/OfficeDetail';
import Measures from './screens/Measures';
import MeasureDetail from './screens/MeasureDetail';
import Translations from './screens/Translations';
import DataHookups from './screens/DataHookups';
import ElectionDetail from './screens/ElectionDetail';
import DistrictIcon from '@mui/icons-material/Public';
import UserManagement from './screens/UserManagement';
import AcceptInvite from './screens/AcceptInvite';
import ResearcherAnalytics from './screens/ResearcherAnalytics';
import Team from './screens/Team';
import TaskList from './screens/TaskList';
import TeamDetail from './screens/TeamDetail';
import CreateAssignment from './screens/CreateAssignment';
import AssignmentDetail from './screens/AssignmentDetail';
import ElectionHealthDetail from './screens/ElectionHealthDetail';
import ElectionCoveragePlan from './screens/ElectionCoveragePlan';
import ElectionProgress from './screens/ElectionProgress';
import ScoutBallotItems from './screens/ScoutBallotItems';
import FeedbackDetail from './screens/FeedbackDetail';
import ResearcherAnalyticsIndividual from './screens/ResearcherAnalyticsIndividual';
import AnalyticsIcon from '@mui/icons-material/Timeline';
import ReportedErrorList from './screens/ReportedErrorList';
import ReportedErrorDetail from './screens/ReportedErrorDetail';
import { Build as BuildIcon } from '@mui/icons-material';
import StateConfig from './screens/StateConfig';

import NavigationPane from './screens/NavigationPane';
import MenuItem from './screens/NavigationPane/MenuItem';
import { ReactComponent as RacesIcon } from './images/races-icon.svg';
import { ReactComponent as ElectionsIcon } from './images/elections-icon.svg';
import { ReactComponent as GlobalIcon } from './images/global-white-icon.svg';
import { ReactComponent as MeasuresIcon } from './images/legal-contract-paper.svg';
import { ReactComponent as DataLink } from './images/cluster-data.svg';
import GavelIcon from '@mui/icons-material/Gavel';
import TaskListIcon from '@mui/icons-material/List';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import rest from '@feathersjs/rest-client';
import superagent from 'superagent';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import DataHookupsBasicCoverage from './screens/DataHookupsBasicCoverage';
import DataHookupsPhotoSelection from './screens/DataHookupsPhotoSelection';
import DataHookupsResults from './screens/DataHookupsResults';
import HomeIcon from '@mui/icons-material/Home';
import ElectionDates from './screens/ElectionDates';
import States from './screens/States';
import StateDetail from './screens/StateDetail';
import Tier2DistrictType from './screens/Tier2DistrictType';
import Tier2DistrictDetail from './screens/Tier2DistrictDetail';
import feathers, { Application } from '@feathersjs/feathers';
import feathersAuth from '@feathersjs/authentication-client';
import { ServiceTypes } from './app/feathers/ServiceTypes';
import './app/feathers/Application';
import { Election } from './app/feathers/elections/Election';

const taskTypes = Object.keys(taskConfigurations);

const theme = {
  font: {
    normal: `font-family: HouschkaRoundedAlt; font-weight: normal;`,
    bold: `font-family: HouschkaRoundedAlt; font-weight: bold;`,
  },
  colors: {
    darkPurple: '#22034F',
    indigo: '#453DB7',
    lightPurple: '#B4B3F8',
    lightBlue: '#94ACF9',
    subtleGrey: '#878787',
    darkGrey: '#313639',
    honeyYellow: '#EFF1B6',
  },
  palette: {
    primary: '#22034F',
    accent: '#453DB7',
  },
};

const muiTheme = createTheme(adaptV4Theme({
  palette: {
    primary: {
      main: '#22034F',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#453DB7',
      contrastText: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: '"HouschkaRoundedAlt"',
    h1: {
      fontSize: '28px',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '24px',
      fontWeight: 'bold',
    },
    h3: {
      fontSize: '20px',
      fontWeight: 'bold',
    },
    h4: {
      fontSize: '18px',
      fontWeight: 'bold',
    },
    h5: {
      fontSize: '14px',
      opacity: 0.7,
      fontWeight: 'bold',
    },
  },
  overrides: {
    MuiTextField: {
      root: {
        backgroundColor: '#FFFFFF',
      },
    },
    MuiSelect: {
      root: {
        backgroundColor: '#FFFFFF',
      },
    },
    MuiButton: {
      contained: {
        '& > span': {
          color: '#FFFFFF',
        },
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: '#D3D3D3',
      },
    },
  },

}));

interface FixMeState {
  user: {
    permissions: string[]
    loggedIn: boolean;
  };
}

const App = () => {
  const dispatch = useDispatch();
  const { loggedIn, permissions } = useSelector((state: FixMeState) => state.user);
  const [feathersClient, setFeathersClient] = React.useState(null as unknown as Application);
  const translationPermission = (permissions || []).includes('translations');
  const userManagementPermission = permissions?.some(permission => ['super-admin', 'editor', 'user-management'].includes(permission));
  const resolveErrorPermission = permissions?.some(permission => ['super-admin', 'editor', 'admin', 'resolve-error'].includes(permission));
  const dataLinkPermissions = (permissions || []).some(p => ['admin', 'super-admin', 'reviewer', 'editor', 'publisher'].includes(p));
  const editorPermission = (permissions || []).some(p => ['admin', 'super-admin', 'editor'].includes(p));
  const homePermissions = (permissions || []).some(p => ['admin', 'super-admin', 'editor', 'publisher', 'reviewer', 'researcher', 'translations'].includes(p));

  const researchPortalPermissions = permissions?.some(p => [
    'super-admin',
    'editor',
    'user-management',
    'publisher',
    'reviewer',
    'researcher',
    'auditor',
    'translations',
  ].includes(p));

  const loadFeathers = async () => {
    const appStaging = feathers<ServiceTypes>();
    appStaging.getService = function(name: string) {
      return appStaging.service(`api/v1/${name}`);
    };

    const endpoint = process.env.NODE_ENV === 'development'
      ? 'http://localhost:3030'
      : 'https://www.branch.vote';
    const restClient = rest(endpoint);
    appStaging.configure(restClient.superagent(superagent));

    // Pass the custom authentication client class as the `Authentication` option
    appStaging.configure(feathersAuth({
      storageKey: 'auth-data-editor',
      path: '/api/v1/authentication',
    }));
    appStaging.getService('finances').timeout = 15000;
    appStaging.getService('races').timeout = 15000;
    appStaging.getService('elections').timeout = 30000;
    appStaging.getService('election-results').timeout = 30000;
    appStaging.getService('candidates').timeout = 20000;
    appStaging.getService('research-tasks').timeout = 20000;

    if (loggedIn) {
      try {
        const result = await appStaging.reAuthenticate();
        dispatch(userLogin(result.user));
      } catch (err) {
        dispatch(userLogout());
      }
    } else {
      dispatch(userLogout());
    }

    setFeathersClient(appStaging);

    // load elections
    const { data } = await appStaging.getService('elections').find({
      query: {
        $limit: 20,
        name: { $ne: 'Test Election' },
        $sort: { date: -1 },
      },
    });
    const keyList = data.map(election => election.key);
    const electionsByKey = data.reduce((acc, election) => {
      const elObj: Record<string, Election> = {};
      elObj[election.key] = election;
      return {
        ...acc,
        ...elObj,
      };
    }, {});

    dispatch(setElections({ list: keyList, byKey: electionsByKey, default: keyList[0] }));
  };

  useEffect(() => {
    loadFeathers();
  }, [feathers]);


  const styles: CSSProperties = {
    position: 'absolute',
    width: '100vw',
    minHeight: '100rvh',
    maxHeight: '100rvh',
    overflowY: 'visible',
    overflowX: 'hidden',
    background: '#F7F9FE',
  };

  return (
    <Div100vh style={styles}>
      <ThemeProvider theme={theme}>
        <MUIThemeProvider theme={muiTheme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <FeathersProvider value={feathersClient}>
              <Router>
                <AppBody>
                  {loggedIn && researchPortalPermissions && (
                    <NavigationPane toHome={{ to: '/elections' }}>
                      {
                        homePermissions &&
                        <MenuItem
                          onClick={{ to: `/home` }}
                          text="Home"
                          icon={<HomeIcon style={{ color: '#FFFFFF', width: '24px', height: '24px' }} />}
                          matchRoutes={['/home']}
                        />
                      }
                      <MenuItem
                        onClick={{ to: '/elections' }}
                        text="Elections"
                        icon={<ElectionsIcon />}
                        matchRoutes={['/elections', '/elections/new', '/elections/.*']}
                        renderChildrenIf={(route: string) => (
                          route.startsWith('/elections/') && route !== '/elections/new'
                        )}
                        modifyChildrenOnClick={(onClick, routeParams) => (
                          (onClick?.to && routeParams?.key)
                            ? { to: onClick.to.replace('SELECTED_ELECTION', routeParams.key) }
                            : onClick
                        )}
                      >
                        <MenuItem
                          onClick={{ to: `/elections/SELECTED_ELECTION/races` }}
                          text="Races"
                          icon={<RacesIcon />}
                          matchRoutes={[
                            '/elections/.*/races/new',
                            '/elections/.*/races/.*/candidates/.*',
                            '/elections/.*/races/.*',
                            '/elections/.*/races',
                          ]}
                        />
                        <MenuItem
                          onClick={{ to: `/elections/SELECTED_ELECTION/measures` }}
                          text="Referendums"
                          icon={<MeasuresIcon />}
                          matchRoutes={[
                            '/elections/.*/measures/new',
                            '/elections/.*/measures/.*',
                            '/elections/.*/measures',
                          ]}
                        />
                        {
                          editorPermission && false &&
                          <MenuItem
                            onClick={{ to: `/elections/SELECTED_ELECTION/team` }}
                            text="Team"
                            icon={<SupervisorAccountIcon style={{ color: '#FFFFFF' }} />}
                            matchRoutes={[
                              '/elections/.*/team',
                              '/elections/.*/team/.*',
                              '/elections/.*/assignments/.*',
                            ]}
                          />
                        }
                        {
                          dataLinkPermissions && false &&
                          <MenuItem
                            onClick={{ to: `/elections/SELECTED_ELECTION/data` }}
                            text="Data Hookups"
                            icon={<DataLink style={{ color: '#FFFFFF' }} />}
                            matchRoutes={[
                              '/elections/.*/data',
                            ]}
                          />
                        }
                      </MenuItem>
                      <MenuItem
                        onClick={{ to: `/offices` }}
                        text="Offices"
                        icon={<GavelIcon style={{ color: '#FFFFFF' }} />}
                        matchRoutes={['/offices', '/offices/.*']}
                      />
                      <MenuItem
                        onClick={{ to: `/states` }}
                        text="Districts"
                        icon={<DistrictIcon style={{ color: '#FFFFFF' }} />}
                        matchRoutes={['/states', '/states/.*']}
                      />
                      {resolveErrorPermission && (
                        <MenuItem
                          onClick={{ to: `/reported-errors` }}
                          text="Errors"
                          icon={<BuildIcon style={{ color: '#FFFFFF' }} />}
                          matchRoutes={['/reported-errors', '/reported-errors/.*']}
                        />
                      )}
                      {translationPermission && (
                        <MenuItem
                          onClick={{ to: `/translations` }}
                          text="Translations"
                          icon={<GlobalIcon style={{ color: '#FFFFFF' }} />}
                          matchRoutes={['/translations']}
                        />
                      )}
                      {
                        editorPermission && (
                          <MenuItem
                            onClick={{ to: `/tasks-list` }}
                            text="Tasks"
                            icon={<TaskListIcon style={{ color: '#FFFFFF' }} />}
                            matchRoutes={['/tasks-list']}
                          />
                        )}
                      {userManagementPermission && (
                        <MenuItem
                          onClick={{ to: `/user-management` }}
                          text="Team"
                          icon={<SupervisorAccountIcon style={{ color: '#FFFFFF' }} />}
                          matchRoutes={['/user-management', '/team/analytics']}
                          renderChildrenIf={(route: string) => (
                            route.startsWith('/user-management') || route.startsWith('/team')
                          )}
                        >
                          <MenuItem
                            onClick={{ to: `/team/analytics` }}
                            text="Analytics"
                            icon={<AnalyticsIcon style={{ color: '#FFFFFF' }} />}
                            matchRoutes={[
                              '/team/analytics',
                            ]}
                          />
                        </MenuItem>
                      )}
                    </NavigationPane>
                  )}
                  <div
                    style={{
                      flex: 1,
                      height: '100vh',
                      overflowY: 'scroll',
                      position: 'relative',
                    }}
                  >
                    <Switch>
                      <PrivateRoute path="/tasks-list">
                        <TaskList />
                      </PrivateRoute>
                      {
                        taskTypes.map((taskType) => (
                          <PrivateRoute key={taskType} path={`/tasks/${taskType}`}>
                            {(taskConfigurations as Record<string, { taskPage: () => ReactNode }>)[taskType].taskPage()}
                          </PrivateRoute>
                        ))
                      }
                      <PrivateRoute path="/elections/:key/measures/:measureId/feedback/:feedbackId">
                        <FeedbackDetail />
                      </PrivateRoute>
                      <PrivateRoute path="/elections/:key/measures/new">
                        <Measures />
                      </PrivateRoute>
                      <PrivateRoute path="/elections/:key/measures/:id">
                        <MeasureDetail />
                      </PrivateRoute>
                      <PrivateRoute path="/elections/:key/dates">
                        <ElectionDates />
                      </PrivateRoute>
                      <PrivateRoute path="/elections/:key/measures">
                        <Measures />
                      </PrivateRoute>
                      <PrivateRoute path="/elections/:key/coverage-plan">
                        <ElectionCoveragePlan />
                      </PrivateRoute>
                      <PrivateRoute path="/elections/:key/progress">
                        <ElectionProgress />
                      </PrivateRoute>
                      <PrivateRoute path="/elections/:key/scout-ballots/:districtId">
                        <ScoutBallotItems />
                      </PrivateRoute>
                      <PrivateRoute path="/reported-errors/:id">
                        <ReportedErrorDetail />
                      </PrivateRoute>
                      <PrivateRoute path="/reported-errors">
                        <ReportedErrorList />
                      </PrivateRoute>
                      <PrivateRoute path="/states/:matchName/state-config">
                        <StateConfig />
                      </PrivateRoute>
                      <PrivateRoute path="/states/:matchName/:tier2Type/:tier2MatchName">
                        <Tier2DistrictDetail />
                      </PrivateRoute>
                      <PrivateRoute path="/states/:matchName/:tier2Type">
                        <Tier2DistrictType />
                      </PrivateRoute>
                      <PrivateRoute path="/states/:matchName">
                        <StateDetail />
                      </PrivateRoute>
                      <PrivateRoute path="/states">
                        <States />
                      </PrivateRoute>
                      <PrivateRoute path="/elections/:key/assignments/new/:userId">
                        <CreateAssignment />
                      </PrivateRoute>
                      <PrivateRoute path="/elections/:key/assignments/:id">
                        <AssignmentDetail />
                      </PrivateRoute>
                      <PrivateRoute path="/elections/:key/team/:userId">
                        <TeamDetail />
                      </PrivateRoute>
                      <PrivateRoute path="/elections/:key/team">
                        <Team />
                      </PrivateRoute>
                      <PrivateRoute path="/elections/:key/races/new">
                        <Races />
                      </PrivateRoute>
                      <PrivateRoute path="/elections/:key/races/:id/candidates/new">
                        <RaceDetail />
                      </PrivateRoute>
                      <PrivateRoute path="/elections/:key/races/:raceId/candidates/:id/feedback/:feedbackId">
                        <FeedbackDetail />
                      </PrivateRoute>
                      <PrivateRoute path="/elections/:key/races/:raceid/candidates/:id">
                        <CandidateDetail />
                      </PrivateRoute>
                      <PrivateRoute path="/elections/:key/races/:id">
                        <RaceDetail />
                      </PrivateRoute>
                      <PrivateRoute path="/elections/:key/data/basic-coverage">
                        <DataHookupsBasicCoverage />
                      </PrivateRoute>
                      <PrivateRoute path="/elections/:key/data/photos">
                        <DataHookupsPhotoSelection />
                      </PrivateRoute>
                      <PrivateRoute path="/elections/:key/data/results">
                        <DataHookupsResults />
                      </PrivateRoute>
                      <PrivateRoute path="/elections/:key/data">
                        <DataHookups />
                      </PrivateRoute>
                      <PrivateRoute path="/elections/:key/races">
                        <Races />
                      </PrivateRoute>
                      <PrivateRoute path="/elections/new">
                        <Elections />
                      </PrivateRoute>
                      <PrivateRoute path="/elections/:key/health">
                        <ElectionHealthDetail />
                      </PrivateRoute>
                      <PrivateRoute path="/elections/:key">
                        <ElectionDetail />
                      </PrivateRoute>
                      <PrivateRoute path="/elections">
                        <Elections />
                      </PrivateRoute>
                      <PrivateRoute path="/createCandidate">
                        <CandidateDetail />
                      </PrivateRoute>
                      <PrivateRoute path="/candidates">
                        <CandidatesList />
                      </PrivateRoute>
                      <PrivateRoute path="/offices/:key">
                        <OfficeDetail />
                      </PrivateRoute>
                      <PrivateRoute path="/offices">
                        <Offices />
                      </PrivateRoute>
                      <PrivateRoute path="/translations">
                        <Translations />
                      </PrivateRoute>
                      <PrivateRoute path="/team/analytics/:userId">
                        <ResearcherAnalyticsIndividual />
                      </PrivateRoute>
                      <PrivateRoute path="/team/analytics">
                        <ResearcherAnalytics />
                      </PrivateRoute>
                      <PrivateRoute path="/user-management">
                        <UserManagement />
                      </PrivateRoute>
                      <Route path="/acceptInvite">
                        <AcceptInvite />
                      </Route>
                      <PrivateRoute path="/home">
                        <Home />
                      </PrivateRoute>
                      <Route path="/">
                        <Login />
                      </Route>
                      <Route path="/logout">
                        <Login />
                      </Route>
                    </Switch>
                  </div>
                </AppBody>
              </Router>
            </FeathersProvider>
          </MuiPickersUtilsProvider>
        </MUIThemeProvider>
      </ThemeProvider>
    </Div100vh>
  );
};

const AppBody = styled.div`
  position: absolute;
  width: 100vw;
  bottom: 0;
  margin: 0;
  top: 0px;
  display: flex;
  flex-direction: row;
`;

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
const PrivateRoute = ({
                        children,
                        permissions = [
                          'super-admin',
                          'find-website',
                          'editor',
                          'user-management',
                          'researcher',
                          'reviewer',
                          'publisher',
                          'auditor',
                          'translations',
                        ],
                        ...rest
                      }: {
  children: ReactNode,
  permissions?: string[],
  path?: string,
}) => {
  const user = useSelector((state: FixMeState) => state.user);
  const { loggedIn } = user;
  const authenticated = loggedIn && isPermitted(user, permissions);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        authenticated ? (
          children
        ) : (
          <Redirect
            to={{
              // If trying to access a page with improper permissions,
              // redirect to dashboard. If trying to access a page without being
              // logged in, redirect to login.
              pathname: loggedIn && user.permissions?.length ? '/home' : '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default App;
