import React, { MouseEvent, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { loadPaginatedFrontend, useFeathers } from '../../app/util';
import { DataDetailToolbar } from '../../components';
import { sortDistricts } from '../../app/util/sort-districts';
import { DistrictIssues } from '../../components/DistrictIssues';
import { Add, DeleteOutline, Map, PeopleAlt } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Application } from '@feathersjs/feathers';
import { ServiceTypes } from '../../app/feathers/ServiceTypes';
import { District } from '../../app/feathers/districts/District';
import { Tier2DistrictTypeAddModal } from '../Tier2DistrictTypeAddModal';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { humanizeDistrictType } from "../../app/util/humanize-district-type";

const tier2IssueTypes = [
  {
    Icon: Map,
    key: 'coverage-incomplete',
    tooltips: [
      'This district has top-level coverage.',
      'This district is not covered!',
    ],
    columnTitle: 'Shapefile'
  },
  {
    Icon: PeopleAlt,
    key: 'population-incomplete',
    tooltips: [
      'This district has population data.',
      'This district is missing population data!',
    ],
    columnTitle: 'Population'
  },
];

const Tier2DistrictType = () => {
  const history = useHistory();
  const { matchName, tier2Type } = useParams<{ matchName: string, tier2Type: string }>();
  const stateCode = matchName.split('-')[0];
  const feathers = useFeathers<Application<ServiceTypes>>();
  const [districts, setDistricts] = useState<(District & { hasShapefile?: boolean })[]>([]);
  const [parentDistrict, setParentDistrict] = useState<District | null>(null);
  const [districtIssues, setDistrictIssues] = useState<Record<string, string[]>>({});
  const [isAddingDistricts, setIsAddingDistricts] = useState(false);
  const [title, setTitle] = useState('');

  async function loadDistricts() {
    const districtService = feathers.getService('districts');
    const stateConfigService = feathers.getService('state-configurations');
    const [parentDistrict, stateConfig] = await Promise.all([
      districtService.find({ query: { matchName } })
        .then(({ data }) => data?.[0]),
      stateConfigService.get(stateCode),
    ]);
    if (!parentDistrict) {
      return;
    }
    setParentDistrict(parentDistrict);
    setTitle(`${humanizeDistrictType(tier2Type, stateConfig.typeConfigurations)} Districts`);

    let [
      districts,
      notCoveredDistricts,
    ] = await Promise.all([
      loadPaginatedFrontend<District & { hasShapefile?: boolean }>(
        'districts',
        { parentId: parentDistrict._id, type: tier2Type },
        feathers,
        500,
      ),
      loadPaginatedFrontend<District>(
        'district-coverage',
        {
          matched: false,
          method: 'geo',
          parentId: parentDistrict._id,
          type: tier2Type,
        },
        feathers,
        100,
      ),
    ]);
    sortDistricts(districts);
    setDistricts(districts);
    const notCoveredDistrictIds = new Set(
      notCoveredDistricts.map(c => c._id),
    );
    const districtIssues = districts.reduce(
      (districts, next) => {
        districts[next._id] = [];
        if (!next.population) {
          districts[next._id].push('population-incomplete');
        }
        const coverageIncomplete = notCoveredDistrictIds.has(next._id);
        next.hasShapefile = !coverageIncomplete;
        if (coverageIncomplete) {
          districts[next._id].push('coverage-incomplete');
        }
        return districts;
      },
      {} as Record<string, string[]>,
    );
    setDistrictIssues(districtIssues);
  }

  useEffect(() => {
    if (!feathers) {
      return;
    }
    loadDistricts();
  }, [feathers]);

  const onUpdateDistricts = async () => {
    await loadDistricts();
  };

  const iconColumnStyling = {
    width: 100,
    maxWidth: 100,
  }

  const removeDistrict = async (event: MouseEvent, districtId: string) => {
    event.preventDefault();
    await feathers.getService('districts').remove(districtId);
    setDistricts(districts.filter(d => d._id !== districtId));
  };

  return (
    <Wrapper>
      <DataDetailToolbar
        navTree={[
          { text: 'Districts', to: '/states' },
          { text: parentDistrict?.name || '--', to: `/states/${matchName}` },
          { text: title },
        ]}
        onBack={() => history.push(`/states/${matchName}`)}
        actionButtonsComponent={
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setIsAddingDistricts(true)}
            endIcon={<Add/>}
          >
            Add
          </Button>
        }
      />
      <div
        style={{
          margin: '28px 0',
          display: 'flex',
          gap: 8,
        }}
      >
        <Typography variant="h2">
          {parentDistrict?.name} {title}
        </Typography>
        <Typography variant="subtitle1">
          ({districts.length})
        </Typography>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '100%' }}>Name</TableCell>
              {
                tier2IssueTypes.map(({ key, columnTitle }) => (
                  <TableCell sx={iconColumnStyling} key={key}>
                    {columnTitle}
                  </TableCell>
                ))
              }
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {districts.map((district, index) => (
              <TableRow
                key={district?._id || index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                component={Link}
                to={`/states/${matchName}/${tier2Type}/${district?.matchName}`}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <TableCell component="th" scope="row">
                  <Typography variant='body1'>
                    {district.longName}
                  </Typography>
                </TableCell>
                <DistrictIssues
                  as='td'
                  style={iconColumnStyling}
                  issues={districtIssues[district._id]}
                  issueTypes={tier2IssueTypes}
                />
                <TableCell style={{ textAlign: 'center' }}>
                  <IconButton
                    color="error"
                    size='small'
                    onClick={(event) => removeDistrict(event, district._id)}
                  >
                    <DeleteOutline style={{ marginBottom: '2px' }}/>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isAddingDistricts && parentDistrict && <Tier2DistrictTypeAddModal
        existingDistricts={districts}
        parent={parentDistrict}
        type={tier2Type}
        onClose={() => setIsAddingDistricts(false)}
        onUpdateDistricts={() => onUpdateDistricts()}
      />}
    </Wrapper>
  );
};


const Wrapper = styled.div`
  width: calc(100% - 2 * 24px);
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: stretch;
`;

export default Tier2DistrictType;
