import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { loadPaginatedFrontend, useFeathers } from '../../app/util';
import { DataDetailToolbar, LoadingSpinner, Tile } from '../../components';
import { Map, PeopleAlt, Settings } from '@mui/icons-material';
import { sortDistricts } from '../../app/util/sort-districts';
import { DistrictIssues } from '../../components/DistrictIssues';
import { IconButton, Tooltip } from '@mui/material';
import { District } from '../../app/feathers/districts/District';
import { Application } from '@feathersjs/feathers';
import { ServiceTypes } from '../../app/feathers/ServiceTypes';
import { humanizeDistrictType } from "../../app/util/humanize-district-type";


const StateDetail = () => {
  const history = useHistory();
  const matchName = useParams<{ matchName: string }>().matchName;
  const stateCode = matchName.split('-')[0];
  const [loading, setLoading] = useState(true);
  const feathers = useFeathers<Application<ServiceTypes>>();
  const [district, setDistrict] = useState<District | null>(null);
  const [subdistrictsByType, setSubDistrictsByType] = useState({} as Record<string, District[]>);
  const [districtTypes, setDistrictTypes] = useState<string[]>([]);
  const [tier2Issues, setTier2Issues] = useState<Record<string, string[]>>({});
  const [humanizedDistrictTypeNames, setHumanizedDistrictTypeNames] = useState<Record<string, string>>({})

  async function loadState() {
    setLoading(true);
    const districtService = feathers.getService('districts');
    const stateConfigService = feathers.getService('state-configurations');
    const [
      districts,
      stateConfig,
    ] = await Promise.all([
      districtService.find({ query: { matchName } }),
      stateConfigService.get(stateCode),
    ]);
    const district = districts.data[0];
    const configuredTypes = stateConfig.typeStructure?.state || [];

    if (!district) {
      return;
    }

    setDistrict(district);
    const coverageLimit = 100;
    const [
      allSubDistricts,
      districtCoverage,
    ] = await Promise.all([
      loadPaginatedFrontend<District>(
        'districts',
        { parentId: district._id },
        feathers,
        500,
      ),
      await feathers.getService('district-coverage')
        .find({
          query: {
            matched: false,
            method: 'geo',
            parentId: district.dmsId,
            $limit: coverageLimit,
          },
        }),
      await feathers.getService('state-configurations')
        .get(stateCode),
    ]);
    const addedDistrictTypes = [
      ...new Set(allSubDistricts.map(({ type }) => type)),
    ];

    let incompleteCoverageTypes: string[];
    if (districtCoverage.total <= coverageLimit) {
      incompleteCoverageTypes = [
        ...new Set(districtCoverage.data.map(({ type }) => type)),
      ];
    } else {
      incompleteCoverageTypes = (
        await Promise.all(
          addedDistrictTypes.map(async (districtType) => {
            const { total } = await feathers.getService('district-coverage')
              .find({
                query: {
                  matched: false,
                  method: 'geo',
                  parentId: district.dmsId,
                  type: districtType,
                  $limit: 0,
                },
              });
            return total && districtType;
          }),
        )
      ).filter((districtType): districtType is string => !!districtType);
    }

    const subdistrictsByType = allSubDistricts.reduce(
      (subdistricts, subdistrict) => {
        subdistricts[subdistrict.type] ||= [];
        subdistricts[subdistrict.type].push(subdistrict);
        return subdistricts;
      },
      Object.fromEntries(configuredTypes.map(type => [type, [] as District[]])),
    );
    const districtTypes = Object.keys(subdistrictsByType);
    const humanizedDistrictTypeNames = districtTypes.reduce(
      (humanizedTypeNames, type) => {
        humanizedTypeNames[type] = humanizeDistrictType(type, stateConfig.typeConfigurations);
        return humanizedTypeNames;
      },
      {} as Record<string, string>,
    );
    setHumanizedDistrictTypeNames(humanizedDistrictTypeNames);
    districtTypes.sort((a, b) => (a && humanizedDistrictTypeNames[a]).localeCompare(b && humanizedDistrictTypeNames[b]));
    setDistrictTypes(districtTypes);
    Object.values(subdistrictsByType).forEach(d => sortDistricts(d));
    setSubDistrictsByType(subdistrictsByType);

    const tier2Issues: Record<string, string[]> = {};
    districtTypes.forEach(districtType => {
      tier2Issues[districtType] = [];
      if (incompleteCoverageTypes.includes(districtType)) {
        tier2Issues[districtType].push('coverage-incomplete');
      }

      const populationIncomplete = !subdistrictsByType[districtType]
        .every(subDistrict => subDistrict.population);
      if (populationIncomplete) {
        tier2Issues[districtType].push('population-incomplete');
      }
      if (!configuredTypes.includes(districtType)) {
        tier2Issues[districtType].push('not-configured');
      }
    });

    setTier2Issues(tier2Issues);
    setLoading(false);
  }

  useEffect(() => {
    if (!feathers) {
      return;
    }
    loadState();
  }, [feathers]);

  const stateIssueTypes = [
    {
      Icon: Map,
      key: 'coverage-incomplete',
      tooltips: [
        'These districts have top-level coverage.',
        'These districts have top-level coverage gaps!',
      ],
    },
    {
      Icon: PeopleAlt,
      key: 'population-incomplete',
      tooltips: [
        'These districts have population data.',
        'These districts are missing population data!',
      ],
    },
    {
      Icon: Settings,
      key: 'not-configured',
      tooltips: [
        'This district type is correctly configured.',
        'This district type is not configured!',
      ],
      to: `${matchName}/state-config`,
    },
  ];

  return (
    <Wrapper>
      <DataDetailToolbar
        navTree={[
          { text: 'Districts', to: '/states' },
          { text: district?.name || '--' },
        ]}
        onBack={() => history.push('/states')}
        actionButtonsComponent={
          <Link
            to={`/states/${matchName}/state-config`}
          >
            <Tooltip title="Configure State">
              <IconButton color="primary">
                <Settings/>
              </IconButton>
            </Tooltip>
          </Link>
        }
      />
      <div style={{ height: '12px' }}/>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      >
        <h2>{district?.name} Districts</h2>
      </div>
      {
        loading
          ?
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <LoadingSpinner />
          </div>
          :
          districtTypes.length
            ?
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 8,
            }}>
              {
                districtTypes.map((districtType) => {
                  return (
                    <Tile
                      key={districtType}
                      shadowEnabled={true}
                      onClick={{ to: `/states/${matchName}/${districtType}` }}
                    >
                      <div style={{
                        display: 'flex',
                        alignItems: 'baseline',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}>
                        <div style={{
                          display: 'flex',
                          alignItems: 'baseline',
                          gap: 8,
                        }}>
                          <h3 style={{
                            textTransform: 'capitalize',
                          }}>
                            {humanizedDistrictTypeNames[districtType]}
                          </h3>
                          <small>
                            ({subdistrictsByType[districtType].length})
                          </small>
                        </div>
                        <DistrictIssues
                          style={{
                            marginBottom: 8,
                            marginRight: 24,
                          }}
                          issues={tier2Issues[districtType]}
                          issueTypes={stateIssueTypes}
                        />
                      </div>
                    </Tile>
                  );
                })
              }
            </div>
            :
            <div>
              No {district?.name} Districts added.
            </div>
      }
    </Wrapper>
  );
};


const Wrapper = styled.div`
  width: calc(100% - 2 * 24px);
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: stretch;
`;

export default StateDetail;
