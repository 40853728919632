import { ModalInner } from '../lower-order';
import { Button, IconButton, InputLabel, MenuItem, Modal, Select, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Add, DeleteOutline } from '@mui/icons-material';

export const StateConfigLinkTypeModal = ({
                                           linkingTypeConfig,
                                           onCancel,
                                           onConfirm,
                                           typeConfigs,
                                         }) => {
  const humanizeLabel = (typeConfig) => {
    return (
      typeConfig?.prettyName ||
      typeConfig?.type
        ?.replaceAll('us-', 'US-')
        ?.replaceAll('-', ' ')
        ?.replace(/\b\w/g, char => char.toUpperCase())
    );
  };

  const [linkedTypes, setLinkedTypes] = useState(
    linkingTypeConfig?.requiredForFullMatch?.split(':')?.[1]?.split(',') || [],
  );

  const linkableTypeConfigs = (
    typeConfigs || []
  ).filter(({ type }) => {
      // Don't allow linking to self
      if (type === linkingTypeConfig?.type) {
        return false;
      }
      const tiers0to1 = [
        'country',
        'state',
      ];
      return !tiers0to1.includes(type);
    },
  ).map((typeConfig) => {
    return {
      ...typeConfig,
      humanizedLabel: humanizeLabel(typeConfig),
    };
  }).sort(
    (a, b) =>
      (a?.humanizedLabel || '')?.localeCompare(b?.humanizedLabel || ''),
  );

  const handleLinkedTypeDelete = (type) => {
    setLinkedTypes(linkedTypes.filter(t => t !== type));
  };

  const handleLinkedTypeAdd = (type, index) => {
    const newLinkedTypes = [];
    if (index < linkedTypes.length) {
      // Edit
      newLinkedTypes.push(
        ...linkedTypes.slice(0, index),
        type,
        ...linkedTypes.slice(index + 1),
      );
    } else {
      // Add
      newLinkedTypes.push(...linkedTypes, type);
    }
    const additionalTypes = typeConfigs.find(t => t.type === type)?.requiredForFullMatch
      ?.split(':')[1]?.split(',') || [];
    if (additionalTypes.length) {
      newLinkedTypes.push(...additionalTypes.filter(
        t => t !== ![type, linkedTypes].includes(type)),
      );
    }
    setLinkedTypes(newLinkedTypes);
  };

  return (
    <Modal
      open={true}
      onClose={() => onCancel()}
    >
      <ModalInner onClose={() => onCancel()}>
        <Typography variant="h3">
          Require &ldquo;{humanizeLabel(linkingTypeConfig)}&rdquo; or…
        </Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            marginTop: 16,
          }}
        >
          {[...linkedTypes, ''].map((type, index) => (
            <div
              key={type}
              style={{
                display: 'flex',
                gap: 16,
                alignItems: 'center',
              }}
            >
              {index === linkedTypes.length && <InputLabel>
                <Add
                  style={{
                    marginTop: 8,
                    marginLeft: 8,
                  }}
                />
              </InputLabel>}
              <Select
                style={{ flex: 1 }}
                value={linkedTypes[index] || ''}
                onChange={({ target }) => handleLinkedTypeAdd(target.value, index)}>
                <MenuItem
                  value={''}
                  disabled={true}
                >
                  Choose a type…
                </MenuItem>
                {linkableTypeConfigs
                  .filter(({ type }) => (index < linkedTypes.length || !linkedTypes.includes(type)))
                  .map(typeConfig => (
                    <MenuItem
                      key={typeConfig.type}
                      value={typeConfig.type}
                    >
                      {typeConfig.humanizedLabel}
                    </MenuItem>
                  ))}
              </Select>
              {!!(index < linkedTypes.length) &&
                <IconButton onClick={() => handleLinkedTypeDelete(type)}>
                  <DeleteOutline color="error" />
                </IconButton>
              }
            </div>
          ))}
        </div>
        <div style={{
          display: 'flex',
          marginTop: 16,
          justifyContent: 'space-between',
        }}>
          <Button
            variant="outlined"
            onClick={() => onCancel()}
          >
            Cancel
          </Button>
          <Tooltip title={!linkedTypes?.length ? 'At least 1 type must be linked!' : null}>
            <div>
              <Button
                variant="contained"
                disabled={!linkedTypes?.length}
                onClick={() => onConfirm(linkingTypeConfig.type, linkedTypes)}
              >
                Done
              </Button>
            </div>
          </Tooltip>
        </div>
      </ModalInner>
    </Modal>
  );
};