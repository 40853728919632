import React from 'react'
import styled from 'styled-components'
import { BackButton } from '../BackButton'
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types';

// A toolbar designed to go at the top of a "Detail" page (e.g. RaceDetail or CandidateDetail)
const DataDetailToolbar = ({
  onBack, // a function or redirect to trigger when the back button is clicked
  navTree = [], // each item should have properties text and optionally "to".
  style,
  title,
  titleSecondary,
  titleComponent, // provide as an alternative to title
  actionButtonsComponent
}) => {
  const bottomEnabled = Boolean(titleSecondary || titleComponent || title);

  const topContent = () => {
    return (
      <Top>
        {onBack && <BackButton onClick={onBack} />}
        {navTree.length > 0 && (
          <NavTree>
            {navTree.map((item, idx) => (
              <div key={idx}>
                {idx !== 0 && <div>/</div>}
                {item.to ? (
                  <Link to={item.to}>{item.text}</Link>
                ) : (
                  <div>{item.text}</div>
                )}
              </div>
            ))}
          </NavTree>
        )}
      </Top>
    )
  }

  const rightContent = () => (
    actionButtonsComponent ? actionButtonsComponent : <div></div>
  )

  const bottomContent = () => (
    <Bottom>
      {titleComponent ? (
        <>{titleComponent}</>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
          }}
        >
          <Typography variant="h1">{title}</Typography>
          {titleSecondary && (
            <Typography
              variant="h3"
              style={{ marginBottom: '4px', marginLeft: '8px' }}
            >
              {titleSecondary}
            </Typography>
          )}
        </div>
      )}
    </Bottom>
  )

  if(bottomEnabled) {
    return (
      <Wrapper style={style}>
        <Left>
          {topContent()}
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent:'space-between', flex: 1 }}>
            {bottomContent()}
            {rightContent()}
          </div>
        </Left>
      </Wrapper>
    )
  } else {
    return (
      <Wrapper style={style}>
        <Left>
          {topContent()}
        </Left>
        {rightContent()}
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-bottom: 14px;
  border-bottom: solid 1px #cccccc;

  background: rgb(247, 249, 254);
`

const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
`

const Top = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
`

const Bottom = styled.div`
  display: flex;
  margin-top: 28px;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  h1 {
    font-size: 28px;
  }
`

const NavTree = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 10px;

  div {
    display: flex;
    flex-direction: row;
  }

  div > div,
  div > a {
    margin-left: 10px;
    ${(props) => props.theme.font.normal}
    font-size: 16px;
  }

  div > a {
    text-decoration: underline;
    color: ${(props) => props.theme.colors.indigo};
  }
`

DataDetailToolbar.propTypes = {
  onBack: PropTypes.func,
  navTree: PropTypes.array,
  style: PropTypes.object,
  title: PropTypes.string,
  titleSecondary: PropTypes.string,
  titleComponent: PropTypes.node,
  actionButtonsComponent: PropTypes.node
}

export { DataDetailToolbar }
