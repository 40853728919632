import React from 'react';
import styled from 'styled-components';
import { useLoadPaginatedFrontend } from '../../app/hooks/useLoadPaginatedFrontend';
import { LoadingSpinner, Tile } from '../../components';

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;

    h1 {
        font-size: 28px;
        margin: 0;
    }

    h2 {
        font-size: 18px;
        opacity: 0.6;
        letter-spacing: 2px;
        margin: 0;
    }
`;

const States = () => {
  const districts = useLoadPaginatedFrontend(
    'districts',
    { type: 'state' },
    [],
    50,
  );

  const loading = !districts?.entity?.length;
  const states = loading
    ? Array.from({ length: 12 })
    : districts.entity.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Wrapper>
      <Row>
        <h1>States</h1>
      </Row>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}>
        {
          states.map((row, index) => (
            <Tile
              key={row?.matchName || index}
              shadowEnabled={true}
              onClick={row ? { to: `/states/${row.matchName}` } : null}
            >
              {row ? <h3>{row.name}</h3> : <LoadingSpinner width={60} />}
            </Tile>
          ))
        }
      </div>
    </Wrapper>
  );
};


const Wrapper = styled.div`
    width: calc(100% - 2 * 24px);
    display: flex;
    flex-direction: column;
    padding: 24px;
    align-items: stretch;
`;

export default States;
